@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700&display=swap');
@import url('./typography.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Inter', sans-serif;
  color: #172b4d;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font-size: 16px;
  }
}
